import React from "react";
import CardContainer from "../../containers/CardContainer";


class Deposit extends React.Component {

    render() {
        return(
            <CardContainer />
        )
    }

}

export default Deposit;